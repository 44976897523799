
export default {
	name: "CoreBlockDivider",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		blockContent() {
			return this.settings.content || null;
		},
	},
};
